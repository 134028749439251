.Home ul {
  margin: 0.133333rem 0.12rem 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Home ul li {
  width: 50%;
  list-style: none;
  height: 3.08rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 0.52rem;
  box-sizing: border-box;
}

.Home ul li.list1 {
  background: url(../assets/home/bj1.png) no-repeat;
  background-size: 100%;
}

.Home ul li.list2 {
  background: url(../assets/home/bj2.png) no-repeat;
  background-size: 100%;
}

.Home ul li.list3 {
  background: url(../assets/home/bj3.png) no-repeat;
  background-size: 100%;
}

.Home ul li.list4 {
  background: url(../assets/home/bj4.png) no-repeat;
  background-size: 100%;
}

.Home ul li h6 {
  font-size: 0.373333rem;
  color: #FAFAFA;
  margin: 0.293333rem 0;
}

.Home ul li p {
  color: #FAFAFA;
  opacity: .6;
  font-size: 0.346667rem;
  margin: 0.293333rem 0;
}