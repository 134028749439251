.headers {
  margin: 0 0.346667rem;
  height: 1.173333rem;
  position: relative;
}

.headers .back {
  position: absolute;
  left: 0;
  top: 0;
  width: 0.8rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.headers .back img {
  height: 0.533333rem;
}

.headers h3 {
  font-size: 0.48rem;
  color: #292A2A;
  text-align: center;
  margin: 0;
  line-height: 1.173333rem;
}

.isHome {
  font-size: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.isHome .out-img {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.093333rem;
}