.Invite {
  height: 100vh;
}

.Invite .invite-num {
  width: 9.773333rem;
  height: 3.08rem;
  margin: 0.266667rem 0.106667rem 0.026667rem;
  background: url(../assets/bj5.png) no-repeat;
  background-size: 9.773333rem 3.08rem;
  color: #FAFAFA;
  padding: 0 0 0 0.52rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Invite .invite-num p {
  margin: 0.253333rem 0;
  font-size: 0.373333rem;
}

.Invite .invite-num span {
  margin: 0.253333rem 0;
  font-size: 0.506667rem;
}

.Invite .tables {
  max-height: calc(100% - 3.08rem - 1.173333rem - 0.293333rem - 0.266667rem);
  width: 9.36rem;
  padding: 0.186667rem 0;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0.026667rem 0.213333rem 0 rgba(0,0,0,0.25);
  border-radius: 0.106667rem;
}

.Invite .tables .tables-header {
  height: 1.133333rem;
  line-height: 1.133333rem;
  font-size: 0.293333rem;
  color: #000;
}

.Invite .tables .tables-body {
  min-height: 0.8rem;
  font-size: 0.293333rem;
  color: #000;
  opacity: .6;
}

.Invite .tables .tables-body span {
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 0.266667rem;
}

.Invite .tables .tables-header span {
  display: inline-block;
}

.Invite .tables .tables-header span,
.Invite .tables .tables-body span {
  word-break: break-all;
  box-sizing: border-box;
}

.Invite .tables .tables-header span:first-of-type,
.Invite .tables .tables-body span:first-of-type {
  padding-left: 0.266667rem;
  width: 1.306667rem;
}

.Invite .tables .tables-header span:nth-of-type(2),
.Invite .tables .tables-body span:nth-of-type(2) {
  width: 1.52rem;
}

.Invite .tables .tables-header span:nth-of-type(3),
.Invite .tables .tables-body span:nth-of-type(3) {
  width: 1.88rem;
}

.Invite .tables .tables-header span:nth-of-type(4),
.Invite .tables .tables-body span:nth-of-type(4) {
  width: 1.653333rem;
  text-align: center;
  justify-content: center;
}

.Invite .tables .tables-header span:nth-of-type(5),
.Invite .tables .tables-body span:nth-of-type(5) {
  text-align: center;
  justify-content: center;
  width: 1.04rem;
}

.Invite .tables .tables-header span:nth-of-type(6),
.Invite .tables .tables-body span:nth-of-type(6) {
  text-align: right;
  padding-right: 0.266667rem;
  width: 1.96rem;
}

.Invite .tables .lists {
  max-height: calc(100vh - 3.08rem - 1.173333rem - 0.293333rem - 0.266667rem - 1.133333rem - 0.2rem);
  overflow: auto;
}