.task {
  height: 100vh;
}

.task .tables {
  max-height: calc(100% - 1.173333rem - 0.266667rem);
  width: 9.36rem;
  padding: 0.186667rem 0;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0.026667rem 0.213333rem 0 rgba(0,0,0,0.25);
  border-radius: 0.106667rem;
}

.task .tables h3 {
  margin: 0;
  font-size: 0.373333rem;
  padding-top: 0.186667rem;
  padding-left: 0.266667rem;
}

.task .tables .tables-header {
  height: 1.133333rem;
  line-height: 1.133333rem;
  font-size: 0.293333rem;
  color: #000;
}

.task .tables .tables-body {
  min-height: 0.8rem;
  font-size: 0.293333rem;
  color: #000;
  opacity: .6;
}

.task .tables .tables-body span {
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 0.266667rem;
}

.task .tables .tables-header span {
  display: inline-block;
}

.task .tables .tables-header span,
.task .tables .tables-body span {
  word-break: break-all;
  box-sizing: border-box;
}

.task .tables .tables-header span:first-of-type,
.task .tables .tables-body span:first-of-type {
  padding-left: 0.266667rem;
  width: 1.986667rem;
}

.task .tables .tables-header span:nth-of-type(2),
.task .tables .tables-body span:nth-of-type(2) {
  width: 2.4rem;
  text-align: center;
  justify-content: center;
}

.task .tables .tables-header span:nth-of-type(3),
.task .tables .tables-body span:nth-of-type(3) {
  width: 2.4rem;
  text-align: center;
  justify-content: center;
}

.task .tables .tables-header span:nth-of-type(4),
.task .tables .tables-body span:nth-of-type(4) {
  text-align: right;
  justify-content: flex-end;
  padding-right: 0.266667rem;
  width: 2.573333rem;
}

.task .tables .tables-header span.del-font,
.task .tables .tables-body span.del-font {
  opacity: .3;
}

.task .tables .tables-header span .del-btn,
.task .tables .tables-body span .del-btn {
  display: block;
  padding: 0 0.306667rem;
  height: 0.493333rem;
  line-height: 0.493333rem;
  background: linear-gradient(147deg, #454545, #23272A);
  border-radius: 0.253333rem;
  color: rgba(255,255,255,0.6);
  font-style: normal;
}

.task .tables .lists {
  max-height: calc(100vh - 1.626667rem - 1.133333rem - 0.72rem);
  overflow: auto;
}