.Index {
  width: 100vw;
  height: 100vw;
  background: url(../assets/bj.png) no-repeat top right;
  background-size: 8.733333rem 7.2rem;
}

.Index h1 {
  color: #2D3038;
  font-size: 0.64rem;
  margin: 0;
  text-align: center;
  padding-top: 3.6rem;
}

.Index .group-input {
  margin: 0 0.64rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.546667rem;
}

.Index .group-input:first-of-type {
  margin-top: 2.466667rem;
}

.Index .group-input span {
  font-size: 0.466667rem;
}

.Index .group-input input {
  height: 0.866667rem;
  margin-top: 0.32rem;
  border: none;
  background: transparent;
  border-bottom: 0.026667rem solid #E8E8E8;
}

.Index .group-input input:focus {
  outline: none;
}

.Index .group-input input::-webkit-input-placeholder {
  font-size: 0.346667rem;
}

.Index .group-input input::placeholder {
  font-size: 0.346667rem;
}

.Index .warn {
  font-size: 0.32rem;
  text-align: right;
  color: #323232;
  margin: 0.493333rem 0.666667rem 1.52rem 0;
}

.Index .warn span {
  color: #0090EF;
}

.Index .button-group {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.Index .adm-button-primary {
  width: 7.466667rem;
  height: 1.173333rem;
  line-height: 0.8rem;
  font-size: 0.426667rem;
  background: #343842;
  border: 0.026667rem solid  #343842;
  border-radius: 0.2rem;
}