.Proxy {
  height: 100vh;
}

.Proxy .proxy-group {
  height: 3.08rem;
  margin: 0.266667rem 0.106667rem 0.026667rem;
  width: 9.773333rem;
  display: flex;
  flex-direction: row;
}

.Proxy .proxy-group .proxy-items {
  width: 50%;
  padding: 0 0 0 0.52rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FAFAFA;
}

.Proxy .proxy-group .items1 {
  background: url(../assets/proxy/bj1.png) no-repeat;
  background-size: 4.946667rem 3.08rem;
}

.Proxy .proxy-group .items2 {
  background: url(../assets/proxy/bj2.png) no-repeat;
  background-size: 4.946667rem 3.08rem;
}

.Proxy .proxy-group p {
  margin: 0.253333rem 0;
  font-size: 0.373333rem;
}

.Proxy .proxy-group span {
  margin: 0.253333rem 0;
  font-size: 0.506667rem;
}

.Proxy .tables {
  max-height: calc(100% - 3.08rem - 1.173333rem - 0.293333rem - 0.266667rem);
  width: 9.36rem;
  padding: 0.186667rem 0;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0.026667rem 0.213333rem 0 rgba(0,0,0,0.25);
  border-radius: 0.106667rem;
}

.Proxy .tables .tables-header {
  display: inline-flex;
  align-items: center;
  height: 1.133333rem;
  font-size: 0.293333rem;
  color: #000;
}

.Proxy .tables .tables-body {
  min-height: 0.8rem;
  font-size: 0.293333rem;
  color: #000;
  opacity: .6;
}

.Proxy .tables .tables-body span {
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 0.266667rem;
}

.Proxy .tables .tables-header span {
  display: inline-block;
}

.Proxy .tables .tables-header span,
.Proxy .tables .tables-body span {
  word-break: break-all;
  box-sizing: border-box;
}

.Proxy .tables .tables-header span:first-of-type,
.Proxy .tables .tables-body span:first-of-type {
  padding-left: 0.266667rem;
  width: 2.933333rem;
}

.Proxy .tables .tables-header span:nth-of-type(2),
.Proxy .tables .tables-body span:nth-of-type(2) {
  width: 0.666667rem;
  text-align: center;
  justify-content: center;
}

.Proxy .tables .tables-header span:nth-of-type(3),
.Proxy .tables .tables-body span:nth-of-type(3) {
  width: 2.226667rem;
  text-align: center;
  justify-content: center;
}

.Proxy .tables .tables-header span:nth-of-type(4),
.Proxy .tables .tables-body span:nth-of-type(4) {
  width: 1.333333rem;
  text-align: center;
  justify-content: center;
}

.Proxy .tables .tables-header span:nth-of-type(5),
.Proxy .tables .tables-body span:nth-of-type(5) {
  text-align: right;
  justify-content: flex-end;
  padding-right: 0.266667rem;
  width: 2.2rem;
}

.Proxy .tables .lists {
  max-height: calc(100vh - 3.08rem - 1.173333rem - 0.293333rem - 0.266667rem - 1.133333rem - 0.2rem);
  overflow: auto;
}